import { getCookie } from '../../lib/js';

/**
 * Filters the lists of ringers based on selections in the UI
 */
const filterRingersRows = () => {
    const filterChoice = document
        .querySelector('[name=filter]:checked')
        .getAttribute('data-value');

    document.querySelectorAll('tr').forEach((element) => {
        const searchTerm = document.querySelector('#search').value;
        const ringerName = element.getAttribute('data-ringer-name');
        const ringerFilter = element.getAttribute('data-filter');
        let displayRinger = false;

        // Search results: enable found rows (or all rows if not searching)
        if (searchTerm) {
            if (new RegExp(searchTerm, 'i').test(ringerName)) {
                displayRinger = true;
            }
        } else {
            displayRinger = true;
        }

        // Filter settings: hide rows that don't match
        if (filterChoice) {
            if (ringerFilter !== filterChoice) {
                displayRinger = false;
            }
        }

        // Always display header rows
        if (!ringerName) {
            displayRinger = true;
        }

        // Apply calculated status
        if (displayRinger) {
            element.style.display = 'table-row';
        } else {
            element.style.display = 'none';
        }
    });
};

/**
 * Toggles the display of a list of ringers
 * @param {string} planName
 */
const toggleRingersTable = (planName) => {
    const planButton = document.querySelector(
        `button[data-plan-name=${planName}]`,
    );
    const ringersTable = document.querySelector(
        `table[data-plan-name=${planName}]`,
    );

    if (planButton.classList.contains('active')) {
        // Already displayed
        planButton.classList.remove('active');
        ringersTable.style.display = 'none';
    } else {
        planButton.classList.add('active');
        ringersTable.style.display = 'table';
    }
};

/**
 * Finds a button in the table row and determines whether it is active
 * @param {HTMLTableRowElement} element
 * @param {string} className
 * @returns {boolean}
 */
const isButtonActive = (element, className) => {
    const button = element.querySelector(`button.${className}`);
    return !!button && button.classList.contains('active');
};

/**
 * Saves an updated ringer entry
 * @param {DOMElement} element Button that was clicked
 */
const saveRinger = (element) => {
    element.setAttribute('disabled', 'disabled');

    const tr = element.parentNode.parentNode;
    const attending = tr.querySelector('input[type=checkbox]').checked;

    const url = `${window.location}${tr.getAttribute('data-ringer-id')}/`;
    const options = {
        headers: {
            'X-CSRFToken': getCookie('csrftoken'),
        },
    };

    if (attending) {
        const contactless = isButtonActive(tr, 'contactless');
        const paid = isButtonActive(tr, 'paid');
        options.body = `contactless=${contactless}&paid=${paid}`;
        options.headers['Content-Type'] =
            'application/x-www-form-urlencoded; charset=utf-8';
        options.method = 'POST';
    } else {
        options.method = 'DELETE';
    }

    fetch(url, options)
        .then((response) => {
            response.text().then((text) => {
                if (response.status == 200) {
                    tr.className = text;
                    tr.setAttribute(
                        'data-filter',
                        attending ? 'present' : 'absent',
                    );
                    element.removeAttribute('disabled');
                } else {
                    document.querySelector('#error-text').textContent = text;
                    document.querySelector('#error-div').style.display =
                        'block';
                }
            });
        })
        .catch(() => {
            document.querySelector('#error-div').style.display = 'block';
        });
};

const practiceAttendances = () => {
    document
        .querySelector('#search')
        .addEventListener('input', filterRingersRows);

    document.querySelectorAll('[data-plan-name]').forEach((element) => {
        element.addEventListener('click', (event) => {
            const planName = event.target.getAttribute('data-plan-name');
            toggleRingersTable(planName);
        });
    });

    document.querySelectorAll('[name=filter]').forEach((element) => {
        element.addEventListener('change', filterRingersRows);
    });

    document.querySelectorAll('tr button.save').forEach((element) => {
        element.addEventListener('click', (event) => saveRinger(event.target));
    });

    document.querySelector('#search').focus();
};

export default practiceAttendances;
