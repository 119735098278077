/**
 * Scripts loaded on every page
 */

/**
 * Bootstrap
 * Import plugins individually as needed.
 * All available plugins are included; uncomment a line to add to the bundle.
 * @see https://getbootstrap.com/docs/5.3/getting-started/webpack/
 */
// import Alert from 'bootstrap/js/dist/alert';
import Button from 'bootstrap/js/dist/button';
// import Carousel from 'bootstrap/js/dist/carousel';
import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';
// import Modal from 'bootstrap/js/dist/modal';
// import Offcanvas from 'bootstrap/js/dist/offcanvas';
// import Popover from 'bootstrap/js/dist/popover';
// import ScrollSpy from 'bootstrap/js/dist/scrollspy';
// import Tab from 'bootstrap/js/dist/tab';
// import Toast from 'bootstrap/js/dist/toast';
// import Tooltip from 'bootstrap/js/dist/tooltip';

// Entrypoint for SCSS
import './index.scss';

// Django apps
import account from './account/js';
import peals from './peals/js';
import steeplage from './steeplage/js';

window.Ascy = {
    account,
    peals,
    steeplage,
};
