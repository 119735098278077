import { getCookie } from '../../lib/js';

/**
 * Index page bootstrap
 */
const index = () => {
    const sendEmailButton = document.querySelector('#sendEmailButton');
    const sendEmailSpinner = document.querySelector('#sendEmailSpinner');
    const sendEmailResult = document.querySelector('#sendEmailResult');

    if (sendEmailButton) {
        sendEmailButton.addEventListener('click', () => {
            sendEmailButton.disabled = true;
            sendEmailSpinner.style.display = 'inline-block';

            fetch('/account/sign_up/email/', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': getCookie('csrftoken'),
                },
            }).then((response) => {
                if (response.status == 200) {
                    sendEmailButton.className = 'btn btn-success';
                    sendEmailSpinner.style.display = 'none';
                    sendEmailResult.innerHTML = '✔';
                } else {
                    sendEmailButton.className = 'btn btn-danger';
                    sendEmailSpinner.style.display = 'none';
                    sendEmailResult.innerHTML = '✘';
                }

                setTimeout(() => {
                    sendEmailButton.className = 'btn btn-secondary';
                    sendEmailButton.disabled = false;
                    sendEmailResult.innerHTML = '';
                }, 3000);
            });
        });
    }
};

/**
 * Account app
 */
export default {
    index,
};
