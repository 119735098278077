/**
 * Creates an "empty" option that is initially selected
 * @returns HTMLOptionElement
 */
const createEmptyOption = () => {
    const option = document.createElement('option');
    option.selected = true;
    option.value = '#';
    option.innerText = 'year';
    return option;
};

/**
 * Creates an option to select a particular year
 * @param {Number} year
 * @param {string} type
 * @returns HTMLOptionElement
 */
const createYearOption = (year, type) => {
    const option = document.createElement('option');
    option.value = `https://allton.org.uk/ascy/ascy${type}_${year}.html`;
    option.textContent = year;
    return option;
};

/**
 * Handler to navigate to a new page when it is chosen in a <select> dropdown
 * @param {Event} event
 */
const onChange = (event) => {
    if (event.target.value !== '#') {
        window.location.href = event.target.value;
    }
};

const pealAnalysisForm = () => {
    document.querySelectorAll('#selectDetailsYear').forEach((element) => {
        element.innerText = '';
        element.appendChild(createEmptyOption());

        for (let year = 2021; year >= 1947; year--) {
            element.appendChild(createYearOption(year, 'peals'));
        }
        for (let year = 1931; year >= 1911; year--) {
            element.appendChild(createYearOption(year, 'peals'));
        }
        for (let year = 1890; year >= 1889; year--) {
            element.appendChild(createYearOption(year, 'peals'));
        }
        for (let year = 1882; year >= 1880; year--) {
            element.appendChild(createYearOption(year, 'peals'));
        }

        element.addEventListener('change', onChange);
    });

    document.querySelectorAll('#selectCompositionsStage').forEach((element) => {
        element.addEventListener('change', onChange);
    });

    document.querySelectorAll('#selectAnalysisYear').forEach((element) => {
        element.innerText = '';
        element.appendChild(createEmptyOption());

        for (let year = 2020; year >= 1957; year--) {
            element.appendChild(createYearOption(year, 'stats'));
        }

        element.addEventListener('change', onChange);
    });
};

export default pealAnalysisForm;
