import practiceAttendances from './practiceAttendances';

/**
 * Index page bootstrap
 */
const index = () => {
    document
        .querySelector('form#date_range')
        .addEventListener('submit', (event) => {
            event.preventDefault();

            const from_date = document.querySelector('#from_date').value;
            const to_date = document.querySelector('#to_date').value;

            window.location = '/steeplage/' + from_date + '/' + to_date + '/';
        });
};

/**
 * Steeplage app
 */
export default {
    index,
    practiceAttendances,
};
